import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    "empty-text": "Chưa có bản kế hoạch sửa chữa nào trong hệ thống",
    style: {"width":"100%"},
    data: _ctx.data,
    border: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        label: "STT",
        width: "55"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.stt), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { label: "Tên vật tư" }, {
        default: _withCtx((scope) => [
          (_ctx.isEdit)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_el_select, {
                  class: "me-2",
                  modelValue: scope.row.category,
                  "onUpdate:modelValue": ($event: any) => ((scope.row.category) = $event),
                  placeholder: "Chọn danh mục",
                  name: "categorySuppliesId"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      value: 0,
                      label: 'Không chọn'
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allCategorySupplies, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.id,
                        label: item.ten_danh_muc,
                        "trigger-on-focus": false,
                        value: item.id
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue"]),
                _createVNode(_component_el_autocomplete, {
                  class: "inline-input ml-2",
                  placeholder: "chọn vật tư",
                  modelValue: scope.row.ten_vat_tu,
                  "onUpdate:modelValue": ($event: any) => ((scope.row.ten_vat_tu) = $event),
                  "fetch-suggestions": _ctx.querySupply,
                  onFocus: ($event: any) => (_ctx.stt = scope.row.stt),
                  onChange: ($event: any) => (_ctx.onChangeSupply(scope.row.stt)),
                  onSelect: ($event: any) => (_ctx.onSelectedSupplyHandler($event, scope.row.stt))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "fetch-suggestions", "onFocus", "onChange", "onSelect"])
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(scope.row.ten_vat_tu), 1))
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "Mã số",
        width: "100"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.ma_vat_tu), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "ĐVT",
        width: "100"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.don_vi_tinh), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "Số lượng",
        width: "120"
      }, {
        default: _withCtx((scope) => [
          (_ctx.isEdit)
            ? (_openBlock(), _createBlock(_component_el_input, {
                key: 0,
                modelValue: scope.row.quantity,
                "onUpdate:modelValue": ($event: any) => ((scope.row.quantity) = $event),
                placeholder: "Số lượng"
              }, null, 8, ["modelValue", "onUpdate:modelValue"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(scope.row.quantity), 1))
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { label: "Hành động" }, {
        default: _withCtx((scope) => [
          (_ctx.isEdit && scope.row.stt < _ctx.data.length)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-lg btn-primary",
                type: "submit",
                onClick: ($event: any) => (_ctx.removeData(scope.row.stt))
              }, " Xoá ", 8, _hoisted_4))
            : _createCommentVNode("", true),
          (_ctx.isEdit && scope.row.stt === _ctx.data.length)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "btn btn-lg btn-primary",
                type: "submit",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addData && _ctx.addData(...args)))
              }, " Thêm "))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}