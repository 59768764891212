
import { defineComponent } from "vue";
import moment from "moment";
import { VatTuService } from "@/core/services/VatTu.service";
import { CategorySupplyService } from "@/core/services/CategorySupply.service";

export default defineComponent({
  name: "dissolution-report-table-add",

  data() {
    let allCategorySupplies: any[] = [];
    return {
      moment,
      data: [
        {
          stt: 1,
          ten_vat_tu: "",
          ma_vat_tu: "",
          don_vi_tinh: "",
          quantity: "",
          category: "",
          id: "",
        },
      ],
      stt: 0,
      allCategorySupplies,
    };
  },

  props: {
    isEdit: {
      type: Boolean,
    },
  },
  async mounted() {
    const {
      data: {
        data: { data },
      },
    } = await CategorySupplyService.fetchAllCategorySupplies();
    this.allCategorySupplies = [...data];
  },
  methods: {
    async querySupply(term: string, callback) {
      const {
        data: { data: items },
      } = await VatTuService.search(term, +this.data[this.stt - 1].category);

      let results = items.map((item) => ({
        value: item.ten_vat_tu,
        ...item,
      }));

      const idsVatTu = this.data.map((item) => +item.id);

      if (idsVatTu && idsVatTu.length) {
        results = results.filter((item) => !idsVatTu.includes(item.id));
      }

      if (results.length > 0) {
        callback(results);
      } else {
        callback([
          {
            value: "",
          },
        ]);
      }
    },

    onChangeSupply(stt) {
      this.data[stt - 1] = {
        stt: this.data[stt - 1].stt,
        ten_vat_tu: "",
        ma_vat_tu: "",
        category: "",
        don_vi_tinh: "",
        quantity: "",
        id: "",
      };
    },

    onSelectedSupplyHandler(item, stt) {
      this.data[stt - 1].id = item.id;
      this.data[stt - 1].ma_vat_tu = item.ma_vat_tu;
      this.data[stt - 1].don_vi_tinh = item.don_vi_tinh || "";
    },

    addData() {
      if (this.data[this.data.length - 1].ten_vat_tu) {
        this.data.push({
          stt: this.data.length + 1,
          ten_vat_tu: "",
          ma_vat_tu: "",
          category: "",
          don_vi_tinh: "",
          quantity: "",
          id: "",
        });
      }
    },

    removeData(stt) {
      this.data = this.data.filter((item) => item.stt !== stt);
      this.data = this.data.map((item, index) => {
        item.stt = index + 1;
        return item;
      });
    },
  },
});
