
import { defineComponent, onMounted, reactive, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRoute, useRouter } from "vue-router";
import DissolutionReportTable from "./dissolution-report-table/DissolutionReportTable.vue";
import DissolutionReportTableAdd from "./dissolution-report-table/DissolutionReportTableAdd.vue";

import {
  ProductItemResponse,
  SupplyWithPivotResponse,
} from "@/core/interfaces/ApiResponses";
import { SupplyItemForRepairingPayload } from "@/core/interfaces/Models";
import { RepairingPlanService } from "@/core/services/RepairingPlan.service";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { DissolutionService } from "@/core/services/Dissolution.service";
import moment from "moment";

export default defineComponent({
  name: "new-dissolution-report",
  components: {
    DissolutionReportTable,
    DissolutionReportTableAdd,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    // component's state
    // ===========================
    const isCreating = ref(true);
    // ===========================

    // form's state
    // ===========================
    const code = ref('');

    const factoryDate = ref(moment());

    // ===========================

    // Local state
    // ===========================
    const outSupplies = ref(false);
    const outDataSupplies = ref<ProductItemResponse>();
    const planId = ref();
    const planName = ref('');
    const planMonth = ref(0);
    const planNote = ref('');

    const productItem = ref<ProductItemResponse>({
      id: 0,
      ten_san_pham: '',
      ma_san_pham: '',
      so_hieu: '',
      mo_ta: '',
      ghi_chu: '',
      slug: '',
      id_nguoi_dung: 0,
      san_pham_meta: [],
      updated_at: '',
      created_at: '',
    });
    // ===========================

    const selectedSupplies = ref<SupplyItemForRepairingPayload[]>([]);

    const tableOutDissolution = ref();

    const repairingPlanForm = reactive({
      name: '',
      month: 0,
      note: '',
    });

    const reportNumber = ref('');

    const onSave = async () => {
      if (!reportNumber.value) {
        Swal.fire({
          text: "Bạn chưa nhập biên bản số",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Đã hiểu!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }

      if (outSupplies.value) {
        let dataDissolution = tableOutDissolution.value.data.filter(
          (item) => item.ten_vat_tu
        );
        if (dataDissolution.length === 0) {
          Swal.fire({
            text: "Bạn chưa nhập ngoài định mức",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Đã hiểu!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return;
        }
        let err = 0;
        dataDissolution.forEach((item) => {
          if (!item.quantity) err++;
        });
        if (err) {
          Swal.fire({
            text: "Bạn chưa nhập số lượng ngoài định mức",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Đã hiểu!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return;
        }
      }

      const {
        data: { data: res },
      } = await DissolutionService.create(
        reportNumber.value,
        moment(factoryDate.value).format("YYYY-MM-DD"),
        planId.value,
        outSupplies.value,
        outSupplies.value ? tableOutDissolution.value.data : []
      );
      await router.push(`/quan-ly/bien-ban-giai-the/chi-tiet/${res.id}`);
    };

    onMounted(async () => {
      setCurrentPageTitle("Trang quản lý biên bản giải thể");
      planId.value = +route.params.planId;
      isCreating.value = route.name === "new-dissolution-report";
      try {
        const {
          data: { data: planResponse },
        } = await RepairingPlanService.get(planId.value);

        planName.value = planResponse.ten_ke_hoach;
        planMonth.value = planResponse.thang;
        planNote.value = planResponse.ghi_chu;

        productItem.value = planResponse.san_pham;

        selectedSupplies.value = [];

        planResponse.dinh_muc_co_dinh.forEach(
          (item: SupplyWithPivotResponse, index) => {
            return selectedSupplies.value.push({
              quantity: item.pivot.so_luong,
              id: item.id,
              is_valid: item.is_valid,
              ten_vat_tu: item.ten_vat_tu,
              ma_vat_tu: item.ma_vat_tu,
              so_luong: item.so_luong,
              don_gia: item.don_gia,
              muc_du_tru_toi_thieu: item.muc_du_tru_toi_thieu,
              muc_du_tru_toi_da: item.muc_du_tru_toi_da,
              thu_hoi: item.thu_hoi,
              cong_ty_duyet: item.cong_ty_duyet,
              id_tai_khoan: item.id_tai_khoan,
              nam: item.nam,
              slug: item.slug,
              updated_at: item.updated_at,
              created_at: item.created_at,
              vat_tu_meta:
                item.vat_tu_meta?.length > 0 ? [...item.vat_tu_meta] : [],
              stt: index + 1,
              don_vi_tinh: item.don_vi_tinh || '',
              ma_ct: item.ma_ct || '',
              ma_tk: item.ma_tk || '',
              ten_tk: item.ten_tk || '',
              so_du_dau_nam: item.so_du_dau_nam || 0,
            });
          }
        );
      } catch (err) {
        Swal.fire({
          text: "Có lỗi xảy ra, mời bạn thử lại sau",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        router.push("/trang-chu");
      }
    });

    return {
      isCreating,
      planId,
      planName,
      planNote,
      planMonth,
      productItem,
      selectedSupplies,
      outSupplies,
      outDataSupplies,
      factoryDate,
      tableOutDissolution,
      repairingPlanForm,
      reportNumber,
      onSave,
      // form
      code,
    };
  },
});
