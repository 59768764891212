import {
  IDonViResponseBase,
  ProductItemResponse,
  RepairingPlanBaseItemResponse,
  RepairingPlanBaseWithProductItemResponse,
  SupplyWithPivotResponse,
} from "@/core/interfaces/ApiResponses";
import { apiServiceInstance } from "@/core/helpers/utils";
import { SupplyRequestPayload } from "../interfaces/Models";

export class RepairingPlanService {
  static list(): Promise<{
    data: {
      data: {
        data: RepairingPlanBaseWithProductItemResponse[];
      };
    };
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/ke-hoach/danh-sach`
    );
  }

  static get(id: number): Promise<{
    data: {
      data: {
        id: number;
        ten_ke_hoach: string;
        thang: number;
        ghi_chu: string;
        created_at: string;
        updated_at: string;
        id_don_vi: number;
        id_san_pham: number;
        is_admin_approved: boolean;
        dinh_muc_co_dinh: SupplyWithPivotResponse[];
        don_vi: IDonViResponseBase;
        san_pham: ProductItemResponse;
      };
    };
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/ke-hoach/chi-tiet/${id}`
    );
  }

  static create(
    name: string,
    month: number,
    note: string,
    productId: number,
    supplies: SupplyRequestPayload[]
  ): Promise<{
    data: {
      data: RepairingPlanBaseItemResponse;
    };
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    bodyFormData.append("keHoachInstance[ten_ke_hoach]", name);
    bodyFormData.append("keHoachInstance[thang]", month.toString());
    bodyFormData.append("keHoachInstance[ghi_chu]", note);
    bodyFormData.append("keHoachInstance[id_san_pham]", productId.toString());
    supplies.forEach(({ id, quantity }) => {
      bodyFormData.append(
        `keHoachInstance[vatTuArray][${id}]`,
        quantity.toString()
      );
    });
    return apiServiceInstance().vueInstance.axios.post(
      "/api/v1/ke-hoach/tao-moi",
      bodyFormData
    );
  }

  static update(
    planId: number,
    name: string,
    month: number,
    note: string,
    productId: number,
    supplies: SupplyRequestPayload[]
  ): Promise<{
    data: {
      data: RepairingPlanBaseItemResponse;
    };
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    bodyFormData.append("keHoachInstance[ten_ke_hoach]", name);
    bodyFormData.append("keHoachInstance[thang]", month.toString());
    bodyFormData.append("keHoachInstance[ghi_chu]", note);
    bodyFormData.append("keHoachInstance[id_san_pham]", productId.toString());
    supplies.forEach(({ id, quantity }) => {
      bodyFormData.append(
        `keHoachInstance[vatTuArray][${id}]`,
        quantity.toString()
      );
    });
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/ke-hoach/cap-nhat/${planId}`,
      bodyFormData
    );
  }
}
