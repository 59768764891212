
import { defineComponent } from "vue";
import moment from "moment";

export default defineComponent({
  name: "dissolution-report-table",
  data() {
    return {
      moment,
    };
  },
  props: {
    items: {
      type: Array,
    },
  },
});
