import {
  IDonViResponseBase,
  ProductItemResponse,
  RepairingPlanBaseItemResponse,
  RepairingPlanBaseWithProductItemResponse,
  IVatTuXuatKhoResponseBase,
} from "@/core/interfaces/ApiResponses";
import { apiServiceInstance } from "@/core/helpers/utils";
import { SupplyRequestPayload } from "../interfaces/Models";

export class DissolutionService {
  static list(): Promise<{
    data: {
      data: {
        data: RepairingPlanBaseWithProductItemResponse[];
      };
    };
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/bien-ban-giai-the/danh-sach`
    );
  }

  static get(id: number): Promise<{
    data: {
      data: {
        id: number;
        ten_ke_hoach: string;
        id_ke_hoach_sua_chua: number;
        has_dinh_muc_dong: boolean;
        so_bien_ban: string;
        thang: number;
        ghi_chu: string;
        created_at: string;
        updated_at: string;
        id_don_vi: number;
        id_san_pham: number;
        is_admin_approved: boolean;
        dinh_muc_co_dinh: IVatTuXuatKhoResponseBase[];
        ngoai_dinh_muc: IVatTuXuatKhoResponseBase[];
        don_vi: IDonViResponseBase;
        san_pham: ProductItemResponse;
        ke_hoach_sua_chua: RepairingPlanBaseItemResponse;
      };
    };
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/bien-ban-giai-the/chi-tiet/${id}`
    );
  }

  static create(
    so_bien_ban: string,
    ngay_vao_xuong: string,
    id_ke_hoach_sua_chua: string,
    has_dinh_muc_dong: boolean,
    supplies?: SupplyRequestPayload[]
  ): Promise<{
    data: {
      data: RepairingPlanBaseItemResponse;
    };
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";

    const bodyFormData = new FormData();
    bodyFormData.append("bienBanGiaiTheInstance[so_bien_ban]", so_bien_ban);
    bodyFormData.append(
      "bienBanGiaiTheInstance[ngay_vao_xuong]",
      ngay_vao_xuong.toString()
    );
    bodyFormData.append(
      "bienBanGiaiTheInstance[id_ke_hoach_sua_chua]",
      id_ke_hoach_sua_chua
    );
    bodyFormData.append(
      "bienBanGiaiTheInstance[has_dinh_muc_dong]",
      has_dinh_muc_dong ? "1" : "0"
    );
    if (supplies?.length) {
      supplies.forEach(({ id, quantity }) => {
        bodyFormData.append(
          `bienBanGiaiTheInstance[dinhMucDongArray][${id}]`,
          quantity.toString()
        );
      });
    } else {
      bodyFormData.append(`bienBanGiaiTheInstance[dinhMucDongArray][0]`, "");
    }
    return apiServiceInstance().vueInstance.axios.post(
      "/api/v1/bien-ban-giai-the/tao-moi",
      bodyFormData
    );
  }

  static createSubReport(
    so_bien_ban: string,
    ngay_vao_xuong: string,
    id_ke_hoach_sua_chua: number,
    supplies: SupplyRequestPayload[],
    parentId: number
  ): Promise<{
    data: {
      data: RepairingPlanBaseItemResponse;
    };
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";

    const bodyFormData = new FormData();
    bodyFormData.append("bienBanGiaiTheInstance[so_bien_ban]", so_bien_ban);
    bodyFormData.append("bienBanGiaiTheInstance[id_cha]", parentId.toString());
    bodyFormData.append(
      "bienBanGiaiTheInstance[ngay_vao_xuong]",
      ngay_vao_xuong
    );
    bodyFormData.append(
      "bienBanGiaiTheInstance[id_ke_hoach_sua_chua]",
      id_ke_hoach_sua_chua.toString()
    );
    bodyFormData.append("bienBanGiaiTheInstance[has_dinh_muc_dong]", "1");
    supplies.forEach(({ id, quantity }) => {
      if (id) {
        bodyFormData.append(
          `bienBanGiaiTheInstance[dinhMucDongArray][${id}]`,
          quantity.toString()
        );
      }
    });
    return apiServiceInstance().vueInstance.axios.post(
      "/api/v1/bien-ban-giai-the/tao-moi",
      bodyFormData
    );
  }

  static update(
    planId: number,
    so_bien_ban: string,
    ngay_vao_xuong: string,
    id_ke_hoach_sua_chua: string,
    has_dinh_muc_dong: boolean,
    supplies?: SupplyRequestPayload[]
  ): Promise<{
    data: {
      data: RepairingPlanBaseItemResponse;
    };
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    bodyFormData.append("bienBanGiaiTheInstance[so_bien_ban]", so_bien_ban);
    bodyFormData.append(
      "bienBanGiaiTheInstance[ngay_vao_xuong]",
      ngay_vao_xuong.toString()
    );
    bodyFormData.append(
      "bienBanGiaiTheInstance[id_ke_hoach_sua_chua]",
      id_ke_hoach_sua_chua
    );
    bodyFormData.append(
      "bienBanGiaiTheInstance[has_dinh_muc_dong]",
      has_dinh_muc_dong ? "1" : "0"
    );
    if (supplies?.length) {
      supplies.forEach(({ id, quantity }) => {
        bodyFormData.append(
          `bienBanGiaiTheInstance[dinhMucDongArray][${id}]`,
          quantity.toString()
        );
      });
    } else {
      bodyFormData.append(`bienBanGiaiTheInstance[dinhMucDongArray][0]`, "");
    }
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/bien-ban-giai-the/cap-nhat/${planId}`,
      bodyFormData
    );
  }

  static delete(id): Promise<{
    data: {
      data: any;
    };
  }> {
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/bien-ban-giai-the/xoa/${id}`
    );
  }
}
