import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    "empty-text": "Chưa có bản kế hoạch sửa chữa nào trong hệ thống",
    style: {"width":"100%"},
    border: true,
    data: _ctx.items
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, { label: "STT" }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.stt), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { label: "Tên vật tư" }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.ten_vat_tu), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { label: "Mã số" }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.ma_vat_tu), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { label: "ĐVT" }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.don_vi_tinh), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { label: "Số lượng" }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.quantity), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { label: "Ghi chú" }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.ghi_chu), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}