import {
  ApiResponse,
  CategorySupplyDataResponse,
} from "@/core/interfaces/ApiResponses";
import { apiServiceInstance } from "@/core/helpers/utils";

export class CategorySupplyService {
  static fetchAllCategorySupplies(): Promise<{
    data: ApiResponse<{
      current_page: number;
      data: CategorySupplyDataResponse[];
      first_page_url: string;
      from: number;
      last_page: number;
      last_page_url: string;
      next_page_url: string | null;
      per_page: number;
      prev_page_url: string | null;
      to: number;
      total: number;
    }>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      "/api/v1/danh-muc-vat-tu/danh-sach"
    );
  }

  static getCategorySupplyDetail(id: number): Promise<{
    data: ApiResponse<CategorySupplyDataResponse>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/danh-muc-vat-tu/chi-tiet/${id}`
    );
  }

  static addNewCategorySupply(
    name: string,
    description: string,
    parentId?: number
  ): Promise<{
    data: ApiResponse<CategorySupplyDataResponse>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    bodyFormData.append("danhMucVatTuInstance[ten_danh_muc]", name);
    bodyFormData.append("danhMucVatTuInstance[mo_ta]", description);
    bodyFormData.append(
      "danhMucVatTuInstance[id_cha]",
      parentId ? parentId.toString() : "0"
    );
    return apiServiceInstance().vueInstance.axios.post(
      "/api/v1/danh-muc-vat-tu/tao-moi",
      bodyFormData
    );
  }

  static updateCategorySupply(
    id: number,
    name: string,
    description: string,
    slug: string,
    parentId?: number
  ) {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    bodyFormData.append("danhMucVatTuInstance[ten_danh_muc]", name);
    bodyFormData.append("danhMucVatTuInstance[mo_ta]", description);
    bodyFormData.append(
      "danhMucVatTuInstance[id_cha]",
      parentId ? parentId.toString() : "0"
    );
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/danh-muc-vat-tu/cap-nhat/${id}`,
      bodyFormData
    );
  }

  static assignSupplies(categorySupplyId: number, supplyIDs: number[]) {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    supplyIDs.forEach((id) => {
      bodyFormData.append("vatTuInstance[]", id.toString());
    });
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/danh-muc-vat-tu/assign/${categorySupplyId}`,
      bodyFormData
    );
  }
}
